import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
} from '@material-ui/pickers';
import AccessTime from '@material-ui/icons/AccessTime';

export default function EgretDate({ className }) {
  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
	<div className={`time-component ${className}`}>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardTimePicker
				variant="inline"
				value={selectedDate}
				onChange={handleDateChange}
				KeyboardButtonProps={{
					'aria-label': 'change time',
				}}
				keyboardIcon={<AccessTime />}
			/>
		</MuiPickersUtilsProvider>
	</div>
  );
}
