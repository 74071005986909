import React from 'react';
import { Button, Dialog, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { COLORS } from '../../app/config';

const styles = (theme) => ({
	root: {
	  margin: 0,
	  padding: '25px 30px 0 30px',
	},
	closeButton: {
	  position: 'absolute',
	  right: theme.spacing(1),
	  top: theme.spacing(1),
	  color: COLORS.BLACK,
	},
	title: {
		width: 100,
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
	  <MuiDialogTitle disableTypography className={classes.root} {...other}>
		<Typography variant="h6" style={{ fontSize: 18 }}>{children}</Typography>
		{onClose ? (
		  <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
		  </IconButton>
		) : null}
	  </MuiDialogTitle>
	);
});
  
const DialogContent = withStyles((theme) => ({
	root: {
		padding: '15px 30px 10px 30px',
	},
}))(MuiDialogContent);
  
const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: '8px 10px 20px 30px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
}))(MuiDialogActions);

function NoLocationDialog(props) {
	const { type, open, setOpen } = props;

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog onClose={handleClose} aria-labelledby="offline-title" open={open}>
			<DialogTitle id="offline-title" onClose={handleClose}>
				Location Warning
			</DialogTitle>
			<DialogContent>
				<span style={{ fontSize: 16 }}>{`Please create a location before adding ${type}.`}</span>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleClose} style={{ backgroundColor: COLORS.SUCCESS }}>Close</Button>
			</DialogActions>
		</Dialog>
	)
}

export default withStyles(styles, { withTheme: true })(NoLocationDialog);