import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import { COLORS } from 'app/config';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" style={{ color: '#5D6776' }} />;
const checkedIcon = <CheckBoxIcon fontSize="small" style={{ color: COLORS.SUCCESS }} />;

const useStyles = makeStyles({
	root: {
    borderRadius: 3,
    backgroundColor: COLORS.PURPLE,
    width: 300
  },
  input: {
    color: COLORS.WHITE,
    padding: '0 0 0 6px !important'
  },
  purple_paper: {
    background: COLORS.PURPLE,
  },
  black_paper: {
    background: COLORS.BLACK,
  },
  option: {
    padding: '3px 16px',
    '&[data-focus="true"]': {
      backgroundColor: COLORS.SUCCESS,
    },
    '&[aria-selected="true"]': {
      '&[data-focus="true"]': {
        '& .MuiSvgIcon-root': {
          color: '#070C13 !important'
        }
      },
    },
  }
});

function RoomTag(props) {
  const { data, onDelete } = props;
  return (
    <div className="flex flex-middle tag-component">
      <span className="name">{data.name}</span>
      <div className="close">
        <CloseIcon fontSize="small" style={{ color: COLORS.BLACK, fontSize: 12 }} onClick={onDelete} />
      </div>
    </div>
  )
}

export default function EgretCheckboxesTags(props) {
  const classes = useStyles();
  const { data, onChange, onDelete, isBlack, containerWidth, placeholder, ...rest } = props;

  const getSelectOption = (option, value) => {
    return option.id === value.id;
  };

  return (
    <div className="checkbox-tags">
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        classes={{
          root: classes.root,
          input: classes.input,
          option: classes.option,
          paper: isBlack ? classes.black_paper : classes.purple_paper,
        }}
        onChange={(event, newValue) => {
          if (onChange)
            onChange(newValue);
        }}
        options={data}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        getOptionSelected={getSelectOption}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8, padding: 4 }}
              checked={selected}
            />
            {option.name}
          </React.Fragment>
        )}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder={placeholder} />
        )}
        {...rest}
      />
      <div className="flex flex-wrap tag-container" style={{ width: containerWidth }}>
        {props.value.map((item, index) => (
          <RoomTag key={index} data={item} index={index} onDelete={() => onDelete(index)}  />
        ))}
      </div>
    </div>
  );
}