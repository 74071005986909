import React  from 'react';
import CheckIcon from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import { COLORS, LOCATION } from 'app/config';
import moment from "moment";

const STATUS_COLORS = [COLORS.SUCCESS, COLORS.SUCCESS, COLORS.WARN, COLORS.ERROR];

function getDeviceType(data) {
	if (data.deviceTypeName === "Custom Device" && data.deviceSpec && data.deviceSpec.metadata && data.deviceSpec.metadata.name){
		return data.deviceSpec.metadata.name
	}
	return data.deviceTypeName;
}

function LocationCard({ data, active, onClick, onSetting, type = LOCATION, isCanEdit = false }) {
	return (
		<div
			className={`locationcard-component ${active ? 'active' : null}`}
			onClick={onClick}
		>
			<div className="flex flex-space-between">
				<div className="name">{data.name}</div>
				{isCanEdit && <SettingsIcon style={{ fontSize: 12 }} onClick={onSetting} />}
			</div>
			<span className="time">{type === LOCATION ? `${data.city} | ${moment(data.localTime).calendar()}` : getDeviceType(data)}</span>
			<div className="flex flex-middle">
				{data.locationSpacesStatusDTOList && data.locationSpacesStatusDTOList.length !== 0 ?
					<>
						{data.locationSpacesStatusDTOList.map((item, index) => (
							index < 3 &&
								<div
									key={index}
									className="circle"
									style={{ background: item.state ? STATUS_COLORS[item.state] : STATUS_COLORS[0] }}
								>	
								</div>
						))}
					</>
					:
					<div className="circle bg-success">
						<CheckIcon style={{ fontSize: 10, color: COLORS.BLACK }} />
					</div>
				}
			</div>
		</div>
	);
}

export default LocationCard;
