import { EgretLayoutSettings } from "../../EgretLayout/settings";

const initialState = {
  settings: {
    ...EgretLayoutSettings
  },
  defaultSettings: {
    ...EgretLayoutSettings
  }
};

export default initialState;