import { EgretLoadable } from 'egret';

const Reports = EgretLoadable({
	loader: () => import('./Reports'),
});

const reportsRoutes = [
	{
		path: '/reports',
		exact: true,
		component: Reports,
	}
];

export default reportsRoutes;
