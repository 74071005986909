import React, { useState } from 'react';
import { Card, Grid, Button, withStyles, CircularProgress, Divider } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { COLORS } from '../../config';

import { loginWithEmailAndPassword } from '../../redux/login/actions';

const styles = (theme) => ({
	wrapper: {
		position: 'relative',
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	inputWrapper: {
		position: 'relative',
		marginBottom: '30px',
	},
	inputContainer: {
		'& input': {
			fontSize: '16px',
			color: '#FEFEFE',
			background: '#070C13',
		},
		'& input::placeholder': {
			color: COLORS.LIGHT_PURPLE,
		},
		'& .MuiInput-underline:hover:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline::before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline::after': {
			borderBottom: 'none',
		},
		'& .MuiFormHelperText-root': {
			marginTop: '8px',
		},
	},
});

function SignIn(props) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const form = React.useRef(null);
	const { classes } = props;

	const handleFormSubmit = (event) => {
		props.loginWithEmailAndPassword({ email, password, history: props.history });
	};

	let token = localStorage.getItem('jwt_token');
	if (token) {
		return <Redirect to={'/reports'} />
	}

	return (
		<div className="signup flex flex-center w-100 h-100vh">
			<div className="p-8">
				<Card className="signup-card position-relative y-center">
					<Grid container>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<div className="px-30 py-28 h-100 bg-black position-relative">
								<span className="text-white title">User Login</span>
								<div className="form-wrapper">
									<ValidatorForm ref={form} onSubmit={handleFormSubmit} autoComplete="off">
										<div className={`w-100 ${classes.inputWrapper}`}>
											<TextValidator
												className={`w-100 ${classes.inputContainer}`}
												placeholder="User Name"
												onChange={(event) => setEmail(event.target.value)}
												type="email"
												name="email"
												value={email}
												validators={['required', 'isEmail']}
												errorMessages={['This field is required', 'username is not valid']}
											/>
											<Divider className={`divider ${email.length > 0 ? 'active' : null}`} />
										</div>
										<div className={`w-100 ${classes.inputWrapper}`}>
											<TextValidator
												className={`w-100 ${classes.inputContainer}`}
												placeholder="Password"
												onChange={(event) => setPassword(event.target.value)}
												name="password"
												type="password"
												value={password}
												validators={['required']}
												errorMessages={['This field is required']}
											/>
											<Divider
												className={`divider ${password.length > 0 ? 'active' : null}`}
											/>
										</div>
										<div className="flex flex-middle flex-space-between mb-8">
											<Button
												className="forget"
												onClick={() => props.history.push('/session/forgot-password')}
											>
												Forgot password?
											</Button>
											<div className={classes.wrapper}>
												<Button
													className="submit"
													variant="contained"
													disabled={props.login.loading}
													type="submit"
												>
													Submit
												</Button>
												{props.login.loading && (
													<CircularProgress
														size={24}
														className={classes.buttonProgress}
													/>
												)}
											</div>
										</div>
									</ValidatorForm>
								</div>
							</div>
						</Grid>
					</Grid>
				</Card>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	loginWithEmailAndPassword: PropTypes.func.isRequired,
	login: state.login,
});
export default withStyles(styles, { withTheme: true })(
	withRouter(connect(mapStateToProps, { loginWithEmailAndPassword })(SignIn))
);
