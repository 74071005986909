// import thunk from "redux-thunk";
// import { createStore, applyMiddleware } from "redux";
//import { compose } from "redux";
// import { composeWithDevTools } from '@redux-devtools/extension';
import { createPromise as createPromiseMiddleware } from 'redux-promise-middleware';
import createAppReducer from './rootReducer';
import getInitialStateFromLocalStorage from './getInitialStateFromLocalStorage';
import {configureStore} from "@reduxjs/toolkit";

const name = (preloadedState = getInitialStateFromLocalStorage(), history) => {
const promiseMiddleware = createPromiseMiddleware({
  promiseTypeSuffixes: [
    'START',
    'SUCCESS',
    'ERROR',
  ],
})

  // const middlewares = [
  //   thunk,
  //   promiseMiddleware,
  // ]

  const appReducer = createAppReducer(preloadedState, history)

  const store = configureStore({
    reducer: appReducer,
    preloadedState: preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(promiseMiddleware),
  });
    // appReducer,
    // preloadedState,
      // compose(
      //     applyMiddleware(...middlewares),
      //     window.devToolsExtension ? window.devToolsExtension() : f => f
    // composeWithDevTools(
        // applyMiddleware(...middlewares)//,
  //     // window.devToolsExtension ? window.devToolsExtension() : f => f
  //   )


  return store
}

export default name;
