import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import TouchRipple from "@material-ui/core/ButtonBase";
import { Helmet } from "react-helmet";
import EgretVerticalNavExpansionPanel from "./EgretVerticalNavExpansionPanel";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  expandIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(90deg)",
  },
  collapseIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)",
  },
});

class EgretVerticalNav extends Component {
  state = {
    collapsed: true,
  };

  renderLevels = (data) => {
    return data.map((item, index) => {
      if (item.children) {
        return (
          <EgretVerticalNavExpansionPanel item={item} key={index}>
            {this.renderLevels(item.children)}
          </EgretVerticalNavExpansionPanel>
        );
      } else {
        return (
          <Fragment key={index}>
            {item.path === this.props.location.pathname && (
              <Helmet>
                <title>{`${process.env.REACT_APP_NAME} - ${item.name}`}</title>
              </Helmet>
            )}
            <NavLink
              to={item.path}
              className="nav-item"
              style={item.style ? item.style : null}
            >
              <TouchRipple key={item.name} name="child" className="w-100">
                {(() => {
                  if (item.icon) {
                    return (
                      <img
                        src={`/assets/svg/${item.icon}.svg`}
                        className="item-icon"
                        alt="sidebar"
                      />
                    );
                  } else {
                    return (
                      <span className="item-icon icon-text">
                        {item.iconText}
                      </span>
                    );
                  }
                })()}
                <span className="text-middle pl-20 item-text">{item.name}</span>
                <div className="mx-auto"></div>
                {item.badge && (
                  <div className={`badge bg-${item.badge.color}`}>
                    {item.badge.value}
                  </div>
                )}
              </TouchRipple>
            </NavLink>
          </Fragment>
        );
      }
    });
  };

  handleClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    return (
      <div className="navigation">
        {this.renderLevels(this.props.navigation)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, {
    })(EgretVerticalNav)
  )
);
