import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	borderCirle: {
		width: 30,
		height: 30,
		borderRadius: '50%',
	},
	title: {
		fontSize: 14,
		marginLeft: 10,
	}
}));

const bgColors = ['success', 'warn', 'error', 'occupied', 'tertiary'];

function CircleType({type, title}) {
	const classes = useStyles();

	return (
		<div className="flex flex-middle my-5">
			<div className={`${classes.borderCirle} bg-${bgColors[type ? type : 0]}`}></div>
			<span className={`${classes.title} text-${bgColors[type ? type : 0]}`}>{title}</span>
		</div>
	);
}

export default CircleType;
