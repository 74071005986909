import { combineReducers } from 'redux';

import login from './login';
import layout from './layout';
import user from './user';
import locations from './locations';
import processors from './processors';
import studios from './studios';
import timeline from './timeline';
import devices from './devices';
import deviceTypes from './deviceTypes';
import users from './users';
import linkedAccounts from './linkedAccounts';
import reports from './reports';
import calendars from './calendars';
import header from './header';

import { initialState } from './initialState';

const {
  snacks: initSnacks, // do not reset snacks but the rest
  ...emptyInitState
} = initialState;

const createAppReducer = initialState => {
  const appReducer = combineReducers({
    login,
    layout,
    user,
    locations,
    processors,
    studios,
    timeline,
    devices,
    deviceTypes,
    users,
    linkedAccounts,
    reports,
    calendars,
    header
  });

  return (state = initialState, action) => {
    const nextState = appReducer(state, action);
    if (
      action.type === '@auth/SIGN_OUT' ||
      (action.error && action.payload.status === 401)
    ) {
      if (localStorage) {
        localStorage.clear();
      }
      return {
        ...nextState,
        ...emptyInitState,
      };
    }

    return nextState;
  };
};

export default createAppReducer;
