import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

const useStyles = makeStyles({
	root: {
		padding: '10px 20px',
		backgroundColor: '#070C13'
	},
	tableRow: {
		cursor: 'default',
		'&:hover': {
			backgroundColor: '#3185D8',
			'& td:first-child': {
				borderTopLeftRadius: 18,
				borderBottomLeftRadius: 18,
			},
			'& td:last-child': {
				borderTopRightRadius: 18,
				borderBottomRightRadius: 18,
			}
		}
	},
});

function createData(uid, name, value) {
  return { uid, name, value };
}

const rows = [
	createData('HE832783', 'HE832783', 'HE832783'),
	createData('JQ019984', 'JQ019984', 'JQ019984'),
	createData('PL389478', 'PL389478', 'PL389478'),
	createData('NA823724', 'NA823724', 'NA823724'),
	createData('PQ873475', 'PQ873475', 'PQ873475'),
	createData('ZQ892342', 'ZQ892342', 'ZQ892342'),
	createData('0Q238478', '0Q238478', '0Q238478'),
];

export default function EgretTable() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>UID</TableCell>
            <TableCell>UID</TableCell>
            <TableCell>UID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} classes={{ root: classes.tableRow }}>
              <TableCell>
                {row.uid}
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
