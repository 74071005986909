import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../app/config';

function BorderLinearProgress(props) {
	const { value, valueLabel, width, height, primary, barColor, showValue, showStyle, className } = props;
	const useStyles = makeStyles((theme) => ({
		root: {
			width: width ? width : '100%',
			height: height ? height : 15,
			borderRadius: 3,
		},
		colorPrimary: {
			backgroundColor: primary ? primary : COLORS.PURPLE,
		},
		bar: {
			borderRadius: 3,
			backgroundColor: barColor ? barColor : COLORS.SUCCESS,
		},
	}));

	const classes = useStyles();

	return (
		<div className={`${className} w-100 progress-component`}>
			<LinearProgress
				classes={{
					root: classes.root,
					colorPrimary: classes.colorPrimary,
					bar: classes.bar
				}}
				variant="determinate"
				value={value}
			/>
			{showValue && <span className="value" style={showStyle}>{`${(valueLabel && valueLabel.toFixed(0)) || value.toFixed(0)}%`}</span>}
		</div>
	);
}

export default BorderLinearProgress;
