import { EgretLoadable } from 'egret';

const Issues = EgretLoadable({
	loader: () => import('./Issues'),
});

const issuesRoute = [
	{
		path: '/issues',
		exact: true,
		component: Issues,
	},
];

export default issuesRoute;
