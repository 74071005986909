import React from 'react';
import { Icon } from '@material-ui/core';
import { DateRangePicker } from "materialui-daterange-picker";
import moment from "moment";

function EgretDateRange(props) {
	const { type, onCallback } = props;
	const [open, setOpen] = React.useState(false);
	const [dateRange, setDateRange] = React.useState({
		startDate: moment().subtract(1, 'month'),
		endDate: moment(),
	});
 
	const toggle = () => {
		setOpen(!open)
	};
	
	const handleChange = (range) => {
		const calcRange = {
			startDate: moment(range.startDate),
			endDate: moment(range.endDate)
		};

		setDateRange(calcRange);
		if (onCallback) onCallback(calcRange)
	}

	const getRangeString = () => {
		return `${dateRange.startDate.format('L')} - ${dateRange.endDate.format('L')}`
	}

  return (
		<div className={`range-component ${type ? type : null}`}>
			<div className="flex flex-middle show" onClick={() => setOpen(!open)}>
				<Icon className="calendar">calendar_today</Icon>
				<span>{getRangeString()}</span>
			</div>
			<div className="control">
				<DateRangePicker
					maxDate={moment()}
					open={open}
					initialDateRange={dateRange}
					toggle={toggle}
					onChange={(range) => handleChange(range)}
				/>
			</div>
		</div>
  );
}

export default EgretDateRange;
