import React from 'react';
import { Icon, Paper, ClickAwayListener } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'app/config';
import moment from "moment";

const STATUS_COLORS = [COLORS.SUCCESS, COLORS.SUCCESS, COLORS.WARN, COLORS.ERROR];

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiList-root': {
			padding: '0 !important',
		},
		'& .MuiListItem-root:hover': {
			backgroundColor: '#070C13',
		},
		boxShadow: '0px 0px 15px #00000029'
	},
}));

function LocationDropDown(props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);
	const { locations, selected, onChange, ...rest } = props;

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	};

	const handleChange = (index, id, event) => {
		if (onChange && id !== selected.id) {
			onChange(index);
		}
		handleClose(event);
	};

	if (!selected) {
		return <></>
	}

	return (
		<div className="location-component" {...rest}>
			<div
				ref={anchorRef}
				className={`flex flex-column selected location-item ${open ? 'active' : ''}`}
				onClick={handleToggle}
			>
				<Icon className="arrow">arrow_drop_down</Icon>
				<div className="flex flex-middle">
					<div className="name">{selected.name}</div>
					<div className="flex flex-middle">
						{selected.locationSpacesStatusDTOList.length !== 0 ?
							<>
								{selected.locationSpacesStatusDTOList.map((item, index) => (
									index < 3 &&
									<div
										key={index}
										className="circle"
										style={{ background: item.state ? STATUS_COLORS[item.state] : STATUS_COLORS[0] }}
									>
									</div>
								))}
							</>
							:
							<div className="circle bg-success">
								<CheckIcon style={{ fontSize: 10, color: COLORS.BLACK }} />
							</div>
						}
					</div>
				</div>
				<span className="time">{`${selected.city} | ${moment(selected.localTime).calendar()}`}</span>
			</div>
			<div className="location-dropdown" style={{ display: open ? 'block' : 'none' }}>
				<Paper classes={{ root: classes.root }}>
					<ClickAwayListener onClickAway={handleClose}>
						<div onKeyDown={handleListKeyDown}>
							{locations && locations.map((data, index) => (
								<div
									key={index}
									onClick={(event) => handleChange(index, data.id, event)}
								>
									<div className={`flex flex-column location-item ${selected.id === data.id ? 'selected-drop' : null}`}>
										<div className="flex flex-middle">
											<div className="name">{data.name}</div>
											<div className="flex flex-middle">
												{data.locationSpacesStatusDTOList.length !== 0 ?
													<>
														{data.locationSpacesStatusDTOList.map((item, index) => (
															index < 3 &&
															<div
																key={index}
																className="circle"
																style={{ background: item.state ? STATUS_COLORS[item.state] : STATUS_COLORS[0] }}
															>
															</div>
														))}
													</>
													:
													<div className="circle bg-success">
														<CheckIcon style={{ fontSize: 10, color: COLORS.BLACK }} />
													</div>
												}
											</div>
										</div>
										<span className="time">{`${data.city} | ${moment(data.localTime).calendar()}`}</span>
									</div>
								</div>
							))}
						</div>
					</ClickAwayListener>
				</Paper>
			</div>
		</div>
	);
}

export default LocationDropDown;
