import axiosAPI from 'app/config/axiosAPI';
import jwtAuthService from "../../services/jwtAuthService";

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export function setUserData(user) {
  return dispatch => {
    dispatch({
      type: SET_USER_DATA,
      data: user
    });
  };
}

export function getUserData() {
  return dispatch => {
    axiosAPI.get(`/user/current`).then(res => {
      const user = res.data
      jwtAuthService.setUser(user)
      return dispatch(setUserData(user))
    });
  };
}

export function updateUserName(data) {
  return dispatch => {
    axiosAPI.put(`/user/currentname`, data).then(res => {
      const user = res.data
      jwtAuthService.setUser(user)
      return dispatch(setUserData(user))
    });
  };
}

export function updateUserPassword(data) {
  return dispatch => {
    axiosAPI.put(`/user/currentpassword`, data).then(res => {
      const user = res.data
      jwtAuthService.setUser(user)
      return dispatch(setUserData(user))
    });
  };
}

export function logoutUser() {
  return dispatch => {
    jwtAuthService.logout();

    window.location.href = `${process.env.REACT_APP_BASE_URL}/signin`;

    dispatch({
      type: USER_LOGGED_OUT
    });
  };
}
