import axiosAPI from 'app/config/axiosAPI';

export const SET_REPORTS = "SET_REPORTS";

export function setReports(reports) {
  return dispatch => {
    dispatch({
      type: SET_REPORTS,
      data: reports
    });
  };
}

export function getReports(params) {
  return dispatch => {
    axiosAPI.get(`/report`, {
      params
    }).then(res => {
      const reports = res.data
      return dispatch(setReports(reports))
    });
  };
}