import login from './login/initialState';
import layout from './layout/initialState';
import user from './user/initialState';
import locations from './locations/initialState';
import processors from './processors/initialState';
import studios from './studios/initialState';
import timeline from './timeline/initialState';
import devices from './devices/initialState';
import deviceTypes from './deviceTypes/initialState';
import users from './users/initialState';
import linkedAccounts from './linkedAccounts/initialState';
import reports from './reports/initialState';
import calendars from './calendars/initialState';
import header from './header/initialState';

export const initialState = {
  login,
  layout,
  user,
  locations,
  processors,
  studios,
  timeline,
  devices,
  deviceTypes,
  users,
  linkedAccounts,
  reports,
  calendars,
  header
};
