import { EgretLoadable } from 'egret';

const User = EgretLoadable({
	loader: () => import('./User'),
});

const userRoutes = [
	{
		path: '/User',
		component: User,
	},
];

export default userRoutes;
