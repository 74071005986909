import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EgretPopover from './EgretPopover';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: '10px 0',
	},
	card: {
		width: '100%',
		background: '#070C13',
		borderRadius: 3,
	},
	cardContent: {
		padding: '25px 20px 20px !important',
	},
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
		fontSize: 16,
		margin: 0,
	},
	content: {
		fontSize: 14,
	},
  borderWrapper: {
		width: 100,
		borderRadius: 3,
		height: 4,
		marginBottom: 5,
	},
	borderCircle: {
		width: 16,
		height: 16,
		borderRadius: '50%',
		borderStyle: 'solid',
		borderWidth: 3,
	}
}));

const bgColors = ['success', 'warn', 'error', 'occupied', 'tertiary'];

function HeaderCard(props) {
	const { type, showCircle = true, title, subTitle, content, ...rest } = props;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Card className={classes.card} elevation={12} {...rest}>
				<CardContent className={classes.cardContent}>
					<div className="flex flex-middle flex-space-between">
						<div>
							<Typography className={classes.title} gutterBottom>
								{title}
							</Typography>
							<div className={`${classes.borderWrapper} bg-${bgColors[type ? type : 0]}`}></div>
						</div>
						{showCircle && <EgretPopover type={type} />}
					</div>
					<Typography className={classes.content} gutterBottom>
						{subTitle}
					</Typography>
					<Typography className={classes.content}>
						{content}
					</Typography>
				</CardContent>
			</Card>
		</div>
	);
}

export default HeaderCard;
