import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    borderCirle: {
        width: 30,
        height: 30,
        borderRadius: '50%',
    },
    title: {
        fontSize: 14,
        marginLeft: 10,
    }
}));

const bgColors = ['purple', 'success', 'warn', 'error', 'occupied', 'tertiary'];

function LedCircle({legendColor, legend, ledColor}) {
    const classes = useStyles();

    return (
        <div className="flex flex-middle my-5">
            <div className={`${classes.borderCirle} bg-${bgColors[ledColor ? ledColor : (legendColor ? legendColor : 0)]}`}></div>
            <span className={`${classes.title} text-${bgColors[legendColor ? legendColor : 0]}`}>{legend}</span>
        </div>
    );
}

export default LedCircle;