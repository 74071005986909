import { EgretLoadable } from 'egret';

const Spaces = EgretLoadable({
	loader: () => import('./Spaces'),
});

const spacesRoutes = [
	{
		path: '/spaces',
		component: Spaces,
	},
];

export default spacesRoutes;
