import React from 'react';
import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	popover: {
    pointerEvents: 'none',
	},
	paper: {
    padding: theme.spacing(1),
    transform: 'translate(0, -18px)',
    '& .popover-content': {
      '& div': {
        cursor: 'default',
        padding: '6px 20px',
        '&:hover' : {
          opacity: 0.6,
        }
      }
    }
  },
}));

const bgColors = ['success', 'warn', 'error', 'occupied', 'tertiary'];

function EgretPopover({ type }) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

	return (
		<div className="popover-component">
			<div
				className={`circle br-${bgColors[type ? type : 0]}`}
        aria-describedby={id}
        onClick={handleClick}
      >
      </div>
      <Popover
        id={id}
        open={open}
        classes={{
          root: classes.paper
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
				<div className={`popover-content bg-${bgColors[type ? type : 0]}`}>
          <div className="item">Mark Complete</div>
          <div className="item">Close this room</div>
          <div className="item">Send message</div>
				</div>
      </Popover>
		</div>
	);
}

export default EgretPopover;
