import { EgretLoadable } from 'egret';

const Guide = EgretLoadable({
	loader: () => import('./Guide'),
});

const guideRoutes = [
	{
		path: '/guide',
		component: Guide,
	},
];

export default guideRoutes;
