import { EgretLoadable } from 'egret';

const Devices = EgretLoadable({
	loader: () => import('./Devices'),
});

const devicesRoutes = [
	{
		path: '/devices',
		exact: true,
		component: Devices,
	},
	{
		path: '/devices/:locationId/:spaceId',
		exact: true,
		component: Devices,
	},
];

export default devicesRoutes;
