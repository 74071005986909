import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getUserData, setUserData } from "../redux/user/actions";
import localStorageService from "../services/localStorageService";

class Auth extends Component {
  state = {};
  
  constructor(props) {
    super(props);

    this.props.setUserData(localStorageService.getItem("auth_user"));
  }

  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

const mapStateToProps = state => ({
  login: state.login
});

export default connect(
  mapStateToProps,
  { getUserData, setUserData }
)(Auth);
