import {
  SET_REPORTS
} from "./actions";
import initialState from './initialState';

const reportsReducer = function(state = initialState, action) {
  switch (action.type) {
    case SET_REPORTS: {
      return {
        ...state,
        ...action.data
      };
    }
    default: {
      return state;
    }
  }
};

export default reportsReducer;
