import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { COLORS } from 'app/config';

const useStyles = makeStyles({
	root: {
    borderRadius: 3,
    backgroundColor: COLORS.BLACK,
    '& .MuiInputBase-root': {
      color: COLORS.WHITE,
      '& input': {
        padding: '7px 15px'
      }
    }
	},
});

export default function EgretTextField({ ...rest }) {
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      label=""
      variant="outlined"
      {...rest}
    />
  );
}
