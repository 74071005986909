import { initialState } from './initialState';

/**
 * As an initial state,
 * we are merging default initial state and data from the localstorage first
 */

const name = () => ({
  ...initialState,
  ...{
    
  },
});

export default name;
