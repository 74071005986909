import createCreateInArrayReducer from '../@reducers/createCreateInArrayReducer';
import createGetWithPaginationReducer from '../@reducers/createGetWithPaginationReducer';
import composeReducers from '../@reducers/composeReducers';
import enhanceReducerWithWriteLocalStorage from '../enhanceReducerWithWriteLocalStorage';
import createFlushReducer from '../@reducers/createFlushReducer';

import initialState from './initialState';
import createUpdateInArrayReducer from '../@reducers/createUpdateInArrayReducer';
import createDeleteInArrayReducer from '../@reducers/createDeleteInArrayReducer';

const NAME = '@linkedAccounts';

/* Reducers */
const flushReducer = createFlushReducer(NAME, []);
const updateReducer = createUpdateInArrayReducer(
  NAME,
  initialState,
);
const createReducer = createCreateInArrayReducer(
  NAME,
  initialState,
);
const deleteReducer = createDeleteInArrayReducer(
  NAME,
  initialState,
);
const getReducer = createGetWithPaginationReducer(
  NAME,
  initialState,
);

const linkedAccountsReducer = composeReducers(initialState)(
  getReducer,
  flushReducer,
  updateReducer,
  createReducer,
  deleteReducer,
);

export default enhanceReducerWithWriteLocalStorage(NAME)(linkedAccountsReducer);
