import React from 'react';
import { Redirect } from 'react-router-dom';

import spacesRoutes from './views/spaces/SpacesRoutes';
import issuesRoutes from './views/issues/IssuesRoutes';
// import homeRoutes from './views/home/HomeRoutes';
import reportsRoutes from './views/reports/ReportsRoutes';
 import guideRoutes from './views/guide/GuideRoutes';
import devicesRoute from './views/devices/DevicesRoutes';
import locationRoute from './views/locations/LocationRoutes';
import userRoute from './views/user/UserRoutes';

import sessionRoutes from './views/sessions/SessionRoutes';

const redirectRoute = [
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/signin" />,
	},
];

const errorRoute = [
	{
		component: () => <Redirect to="/session/404" />,
	},
];

const routes = [
	...sessionRoutes,
	...spacesRoutes,
	...issuesRoutes,
	// ...homeRoutes,
	...reportsRoutes,
	 ...guideRoutes,
	...devicesRoute,
	...locationRoute,
	...userRoute,
	...redirectRoute,
	...errorRoute,
];

export default routes;
