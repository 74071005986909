import { EgretLoadable } from 'egret';

const Locations = EgretLoadable({
	loader: () => import('./Locations'),
});

const locationRoutes = [
	{
		path: '/system',
		component: Locations,
	},
];

export default locationRoutes;
