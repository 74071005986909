import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from "moment";

export default function EgretDate({ className, onCallback }) {
  const [selectedDate, setSelectedDate] = React.useState(moment());

  const handleDateChange = (date) => {
    setSelectedDate(date);
	if (onCallback) onCallback(date)
  };

  return (
	<div className={`date-component ${className}`}>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				disableToolbar
				inputProps={{ readOnly: true }}
				variant="inline"
				format="MM/dd/yyyy"
				value={selectedDate}
				onChange={handleDateChange}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
			/>
		</MuiPickersUtilsProvider>
	</div>
  );
}
